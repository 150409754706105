import "./Gallery.scss";
import GallerySection from "./GallerySection";
import Logo from "../assets/logo.jpg"

function Gallery() {
	return (
		<div className="gallery">
			
			<div className="gallery-wrapper d-flex">
				<div className="container d-flex justify-content-center">
					<div className="row align-content-center">
						<div className="col-lg-12">
							<div className="banner-heading">
								<div className="d-inline-block">
									<h1>Gallery</h1>
									<div className="heading-underline">
										<span></span>
										<span>
											<img
												src={Logo}
												alt=""
												className=""
												style={{
													marginTop: "-3rem",
												}}
											/>
										</span>
										<span></span>
									</div>
								</div>
								<h2 className="text-white">
									Take a Look Inside
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="our-gallery mt-5 mb-5">
				<div className="container">
					<GallerySection />
				</div>
			</div>
		</div>
	);
}

export default Gallery;
