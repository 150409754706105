import './Achievement.scss'

function Achievement() {
  return (
    <div className='achievement'>
      <div className="achievement-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="achievement-services">Gallery
              </h1>
              <h1 className="achievement-services-2 text-white">TAKE A LOOK INSIDE
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Achievement