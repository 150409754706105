import "./Home.scss";
// import Chef from "../assets/chef.jpg";
import Slider1 from "../assets/catering-services9.jpg";
import Slider2 from "../assets/slider2.jpg";
import Slider3 from "../assets/catering-service4.jpg";
import Slider4 from "../assets/catering-services7.jpg";
import About from "../assets/about.jpg";
import ServiceProvider from "../assets/serviceprovider.jpg";
import Owner1 from "../assets/owner1.jpg";
import Owner2 from "../assets/owner.jpg";
import Owner3 from "../assets/owner3.jpg";

import Staff from "../assets/Staff.jpeg";
import Buffet from "../assets/buffet.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdNextPlan } from "react-icons/md";
import Card from 'react-bootstrap/Card';

// import image1 from "../assets/image1.webp";
// import image2 from "../assets/image2.jpg";
// import image3 from "../assets/image3.webp";
// import image4 from "../assets/image4.webp";
// import image5 from "../assets/image5.webp";
// import image6 from "../assets/image6.webp";
// import image7 from "../assets/image7.webp";
// import image8 from "../assets/image8.webp";
// import image9 from "../assets/image9.webp";

import FoodStyling from "../assets/foodstyling.jpg";
// import Burger from "../assets/burger.jpg";
import Carousel from "react-bootstrap/Carousel";

import GallerySection from "./GallerySection";
// import { Parallax, ParallaxLayer } from "@react-spring/parallax";

import Logo from "../assets/logo.jpg";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faMobile } from "@fortawesome/free-solid-svg-icons";


function Home() {
	//   const currentImage = images[index];
	//   const nextIndex = (index + 1) % images.length;
	//   const nextImage = images[nextIndex] || currentImage;
	//   const prevIndex = (index + images.length - 1) % images.length;
	//   const prevImage = images[prevIndex] || currentImage;

	//   const handleClick = (index, item) => setIndex(index);
	//   const handleClose = () => setIndex(-1);
	//   const handleMovePrev = () => setIndex(prevIndex);
	//   const handleMoveNext = () => setIndex(nextIndex);
	// const alignCenter = { display: 'flex', alignItems: 'center' }
	const alignCenter = {};
	return (
		<div className="Home">

			<div className="caters-chef d-flex">
				<div className="container d-flex justify-content-center">
					<div className="row align-content-center">
						<div className="col-lg-12">
							<div className="banner-heading">
								<div className="d-inline-block">
									<h1>Anil Caterers</h1>
									<div className="heading-underline">
										<span></span>
										<span>
											<img
												src={Logo}
												alt=""
												className=""
												style={{
													"marginTop": "-3rem",
												}}
											/>
										</span>
										<span></span>
									</div>
								</div>
								<h2 className="text-white">
									The Best Caterers In Lucknow - Since 2000
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="caters-dinner">
				<div className="container d-flex">
					<div className="row justify-content-center align-self-center">
						<div className="col-lg-12">
							<div className="d-inline-block mb-3">
								<h1 className="our-philospy section-heading mb-3">
									Our Perspective

								</h1>
								<div className="heading-underline">
									<span></span>
									<span>
										<img
											src={Logo}
											alt=""
											className=""
											style={{ "marginTop": "-2rem" }}
										/>
									</span>
									<span></span>
								</div>
							</div>

							<h1 className="our-philospy-2 text-white">
								ANIL CATERERS THE CULINARY EXCELLENCE - SINCE
								2002
							</h1>
							<h5 className="our-philospy-para text-white">
								Anil Caterers is a comprehensive catering company that specializes in both vegetarian and non-vegetarian dishes, offering exceptional presentation, outstanding flavor, and creative event styling. We continually update our menu with fresh and innovative ideas to meet the diverse needs of our clients. Our catering services are distinguished by our commitment to excellence and uniqueness.
							</h5>
						</div>
					</div>
				</div>
			</div>

			<div className="about-my-caters">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-inline-block mb-5">
								<h1 className="About-heading mb-4 section-heading">
									About My Caterers
								</h1>
								<div className="heading-underline">
									<span></span>
									<span>
										<img
											src={Logo}
											alt=""
											className=""
											style={{ "marginTop": "-2rem" }}
										/>
									</span>
									<span></span>
								</div>
							</div>
						</div>

						<div className="col-lg-6 pb-5 ">
							<div className="align-items-center">
								<h5 className="text-start">
									Chef Anil Gupta, the founder of Anil Caterers in Lucknow, is a highly esteemed culinary professional with over a decade of experience. He has honed his skills in some of the most prestigious five-star hotels, including Mughal Sheraton, Trident Oberoi, Taj Palace Delhi, Holiday Inn, and Taj Mahal Lucknow. His extensive background and expertise make him one of the most recognized chefs in the industry.

									Anil Caterers is a renowned catering service known for delivering exceptional culinary experiences tailored to every occasion. With a commitment to quality and innovation, Anil Caterers offers a diverse menu that blends traditional flavors with modern presentations, ensuring a memorable dining experience for all guests. Whether it's a grand wedding, a corporate event, or a private gathering, Anil Caterers prides itself on attention to detail, impeccable service, and creating moments that leave a lasting impression.
								</h5>

								<h4 className="text-end mb-5">
									Mr. Anil Gupta (Director)
								</h4>
							</div>

						</div>

						<div className="col-lg-6">
							<img src={Owner2} alt="" className="w-100 mb-5" />
						</div>
					</div>
				</div>
			</div>

			<div className="our-execution">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 pb-5">
							<div className="d-inline-block mb-3">
								<h1 className="section-heading mb-3">
									Our Execution Specialization
								</h1>
								<div className="heading-underline">
									<span></span>
									<span>
										<img
											src={Logo}
											alt=""
											className=""
											style={{ "marginTop": "-2rem" }}
										/>
									</span>
									<span></span>
								</div>
							</div>

						</div>

						<div className="col-lg-6 mt-5">
							<img src={Staff} alt="" className="w-100" />
						</div>

						<div className="col-lg-6 align-self-center mt-5">
							<div className="d-inline-block carousel-heading1">
								<h2>EXOTIC VENUE CATERING
								</h2>

								<div className="heading-underline-small">
									<span></span>
									<span className="circle">

									</span>
									<span></span>
								</div>

							</div>
							<h5 className="text-center mt-4">
								One of the key elements of a memorable destination wedding is exceptional food. At Anil Caterers, we aim to impress your guests with a wide variety of choices. Select from an array of cuisines, and we’ll create a feast fit for royalty! Our chefs are dedicated to excellence and creativity in every dish. Whether it’s a welcome breakfast, baraat, cocktail party, wedding banquet, or reception gala, we provide a tailored menu for each event, accompanied by friendly and efficient service.
							</h5>

							<div>
								<h4 className="start-planning-btn">
									Start Planning<MdNextPlan />
								</h4>
							</div>
						</div>

						<div className="col-lg-6 align-self-center mt-5	">
							<div className="d-inline-block carousel-heading">
								<h2>BUFFET DISPLAY DESIGN
								</h2>

								<div className="heading-underline-small">
									<span></span>
									<span className="circle">

									</span>
									<span></span>
								</div>

							</div>

							<h5 className="text-center">
								Experience luxury, elegance, and sophistication with your wedding buffet. At Anil Caterers, our exotic buffet designs offer a vibrant culinary experience for your distinguished guests. Explore a selection of modern eateries featuring inventive cuisine, along with delectable Indian and international vegetarian dishes, and irresistible desserts.
							</h5>

							<div>
								<h4 className="start-planning-btn">
									Start Planning<MdNextPlan />
								</h4>
							</div>
						</div>

						<div className="col-lg-6 mt-5">
							<img src={Buffet} alt="" className="w-100" />
						</div>


						<div className="col-lg-6 mt-5">
							<img src={FoodStyling} alt="" className="w-100" />
						</div>

						<div className="col-lg-6 align-self-center  ">
							<div className="d-inline-block carousel-heading">
								<h2>DISH PLATING
								</h2>

								<div className="heading-underline-small">
									<span></span>
									<span className="circle">

									</span>
									<span></span>
								</div>

							</div>
							<h2></h2>
							<h5 className="text-center">
								Similar to the role of a makeup artist or hairstylist on a fashion shoot, a food stylist at Anil Caterers is crucial in presenting and preparing dishes for the camera. This highly specialized job demands years of experience to master the art of beautiful presentation, and Anil Caterers excels in this area.
							</h5>

							<div>
								<h4 className="start-planning-btn">
									Start Planning{" "}<MdNextPlan />
								</h4>
							</div>
						</div>



					</div>
				</div>
			</div>

			<div className="details-matter">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-inline-block mb-3">
								<h1 className="section-heading mb-3">
									Precision in Every Aspect

								</h1>
								<div className="heading-underline">
									<span></span>
									<span>
										<img
											src={Logo}
											alt=""
											className=""
										// style={{ "marginTop": "-2rem" }}
										/>
									</span>
									<span></span>
								</div>
							</div>
							<h1 className="details-heading-2 text-white">
								YOUR DREAM, PERFECTLY PERSONALIZED

							</h1>

							<p className="details-para">
								In addition to vegetarian options, we offer a diverse range of cuisines, including traditional Asian, Middle Eastern, Italian, French, and Continental dishes. Our approach is to thoughtfully plan and tailor the menu, ensuring exceptional presentation and meticulous food planning to provide a wide array of service options.
							</p>

						</div>
					</div>
				</div>
			</div>

			<div className="container mt-5 mb-5" >
				<Carousel fade variant="dark" controls={false} slide={true} interval={3000} className="silder-page">
					<Carousel.Item>
						<div className="row">
							<div className="col-lg-6">
								<img src={Slider1} alt="" className="w-100 " />
							</div>

							<div className="col-lg-6 align-self-center mt-5">
								<div className="d-inline-block carousel-heading">
									<h2>Elegant Evening Menu
									</h2>

									<div className="heading-underline-small">
										<span></span>
										<span className="circle">

										</span>
										<span></span>
									</div>

								</div>
								<div className="d-flex justify-content-evenly mt-2">
									<h6>Weddings</h6>
									<h6>Anniversary</h6>
									<h6>Baby Showers</h6>
								</div>
								<h5 className="text-center mt-4">
									A Elegant Evening Menu is designed for large gatherings and typically includes a meal (either a sit-down dinner or buffet), entertainment, and possibly an awards segment, all themed to the event. You can choose from a selection of pre-designed menus tailored to various tastes or collaborate with us to craft a custom menu that suits your specific needs.
								</h5>

							</div>

						</div>
					</Carousel.Item>

					<Carousel.Item>
						<div className="row">
							<div className="col-lg-6">
								<img src={Slider2} alt="" className="w-100 " />
							</div>

							<div className="col-lg-6 align-self-center mt-5">
								<div className="d-inline-block carousel-heading">
									<h2>Cocktail and Pre-Wedding Menu
									</h2>

									<div className="heading-underline-small">
										<span></span>
										<span className="circle">

										</span>
										<span></span>
									</div>

								</div>
								<div className="d-flex justify-content-evenly mt-2">
									<h6>Weddings</h6>
									<h6>Anniversary</h6>
									<h6>Baby Showers</h6>
								</div>
								<h5 className="text-center mt-4">
									Parties and food go hand in hand! For a high-budget event, the grand Cocktail and Sangeet Menu buffet should be impressive enough to delight your guests. A Cocktail and Sangeet party needs to be both stunning and glamorous, and exceptional food is key to achieving that. Anil Caterers and their skilled team offer meticulously curated menus for your Cocktail and Sangeet celebrations, ensuring that your special day is unforgettable.
								</h5>

							</div>

						</div>
					</Carousel.Item>

					<Carousel.Item>
						<div className="row">
							<div className="col-lg-6">
								<img src={Slider3} alt="" className="w-100 " />
							</div>

							<div className="col-lg-6 align-self-center mt-5">
								<div className="d-inline-block carousel-heading">
									<h2>Allium-Free Menu
									</h2>

									<div className="heading-underline-small">
										<span></span>
										<span className="circle">

										</span>
										<span></span>
									</div>

								</div>
								<div className="d-flex justify-content-evenly mt-2">
									<h6>Weddings</h6>
									<h6>Anniversary</h6>
									<h6>Baby Showers</h6>
								</div>
								<h5 className="text-center mt-4">
									Sometimes, you might prefer to exclude onions and garlic from your meals. At Anil Caterers, we excel at creating delicious curries without these ingredients. Our dishes are crafted to ensure that the absence of these traditional components doesn't compromise on taste or flavor.
								</h5>

							</div>

						</div>
					</Carousel.Item>

					<Carousel.Item>
						<div className="row">
							<div className="col-lg-6">
								<img src={Slider4} alt="" className="w-100 " />
							</div>

							<div className="col-lg-6 align-self-center mt-5">
								<div className="d-inline-block carousel-heading">
									<h2>Introductory Dishes
									</h2>

									<div className="heading-underline-small">
										<span></span>
										<span className="circle">

										</span>
										<span></span>
									</div>

								</div>
								<div className="d-flex justify-content-evenly mt-2">
									<h6>Weddings</h6>
									<h6>Anniversary</h6>
									<h6>Baby Showers</h6>
								</div>
								<h5 className="text-center mt-4">
									Introductory dishes refer to the initial course or small servings offered at the beginning of a meal, designed to prepare the palate and set the tone for the courses that follow. These dishes are typically light and flavorful, often including appetizers or small portions that provide a preview of the main meal. They can vary widely depending on the cuisine but usually focus on whetting the appetite without being too filling.


									{/* A Deluxe Tea menu typically features light fare, including scones, finger sandwiches, lemon curds, and herbed butter. While there are no strict rules for a traditional High Tea menu, it generally includes a selection of sandwiches and a variety of sweet treats. */}
								</h5>

							</div>

						</div>
					</Carousel.Item>

				</Carousel>
			</div>


			<div className="card-provider">
				<div className="container">
					<div className="row justify-content-around">
						{/* <div className="col-md-12 d-flex justify-content-around"> */}
						<div className="col-lg-3">
							<div className="card-holder">
								<Card >
									<Card.Img variant="top" src={Owner1} />
									<Card.Body>
										<h2 className="card-owner">Ashish Gupta</h2>
										<h5>
											Service Provider
										</h5>
										{/* <Button variant="primary">Go somewhere</Button> */}

										<div className="card-contact-form d-flex justify-content-center">

											<p>Arranging tables, seating, buffet stations, and other essential elements like décor or tableware as per the event's needs.</p>
											{/* <ul className="card-list d-flex justify-content-center">
												<li className="px-2" >
													<a href="#">
														<FontAwesomeIcon icon={faFacebook} />
													</a>
												</li>
												<li className="px-2">
													<a href="#">
														<FontAwesomeIcon icon={faInstagram} />
													</a>
												</li>
												<li className="px-2">
													<a href="#">
														<FontAwesomeIcon icon={faMobile} />
													</a>
												</li>

											</ul> */}

										</div>

									</Card.Body>
								</Card>
							</div>
						</div>

						<div className="col-lg-3">
							<div className="card-holder">
								<Card >
									<Card.Img variant="top" src={Owner2} />
									<Card.Body>
										<h2 className="card-owner">Anil Gupta</h2>
										<h5>
											Owner
										</h5>
										{/* <Button variant="primary">Go somewhere</Button> */}

										<div className="card-contact-form d-flex justify-content-center">
											<p> Ensuring smooth day-to-day operations, including staffing, scheduling, and managing events.</p>
											{/* <ul className="card-list d-flex justify-content-center">
												<li className="px-2" >
													<a href="#">
														<FontAwesomeIcon icon={faFacebook} />
													</a>
												</li>
												<li className="px-2">
													<a href="#">
														<FontAwesomeIcon icon={faInstagram} />
													</a>
												</li>
												<li className="px-2">
													<a href="#">
														<FontAwesomeIcon icon={faMobile} />
													</a>
												</li>

											</ul> */}

										</div>

									</Card.Body>
								</Card>
							</div>
						</div>

						<div className="col-lg-3">
							<div className="card-holder">
								<Card >
									<Card.Img variant="top" src={Owner3} />									<Card.Body>
										<h2 className="card-owner">Piyush Gupta</h2>
										<h5>
											Service Provider
										</h5>
										{/* <Button variant="primary">Go somewhere</Button> */}

										<div className="card-contact-form d-flex justify-content-center">
											<p>Arranging tables, seating, buffet stations, and other essential elements like décor or tableware as per the event's needs.</p>
											{/* <ul className="card-list d-flex justify-content-center">
												<li className="px-2" >
													<a href="#">
														<FontAwesomeIcon icon={faFacebook} />
													</a>
												</li>
												<li className="px-2">
													<a href="#">
														<FontAwesomeIcon icon={faInstagram} />
													</a>
												</li>
												<li className="px-2">
													<a href="#">
														<FontAwesomeIcon icon={faMobile} />
													</a>
												</li>

											</ul> */}

										</div>

									</Card.Body>
								</Card>
							</div>
						</div>

					</div>
				</div>
			</div>




			<div className="mt-5">
				<div className="container">
					<GallerySection />
				</div>
			</div>
		</div>
	);
}

export default Home;

