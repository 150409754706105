import React, { useCallback, useEffect, useMemo, useRef, useState,  } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/logo.jpg";
import "./Header.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

export default function Header() {
  const [animateHeaderTransparent, setAnimateHeaderTransparent] = useState({});
  const [animateHeaderHeight, setAnimateHeaderHeight] = useState({});
  const [animateHeaderBool, setAnimateHeaderBool] = useState(false);
  const handleScroll = useCallback(() => {
    if(window.scrollY > 120) {
      setAnimateHeaderBool(true);
    } else {
      setAnimateHeaderBool(false);
    }
  });
  
  useMemo(function() {
    console.log(animateHeaderBool);
    if(animateHeaderBool) {
      setAnimateHeaderTransparent({backgroundColor: "rgb(0 0 0 / 70%)"});
      setAnimateHeaderHeight({width: "8rem"});
      
    } else {
      setAnimateHeaderTransparent({});
      setAnimateHeaderHeight({});
    }
  }, [animateHeaderBool]);
  useEffect(() => {
    console.log("sdsdd");
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [expended, setExpended] = useState(false);
  const handleClick = (e) => {
    setExpended(false);
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    console.log(location);
    document.querySelector("body").classList.remove("animate-y");
    setTimeout(() => {
      // document.querySelector("body").classList.add("animate-y")

    })
    return () => {
      document.querySelector("body").classList.remove("animate-y")
    }
  }, [location])
	return (
		<div className="Header">
			<Navbar expanded={expended} expand="lg"  className="bg-body-tertiary-cst" style={animateHeaderTransparent}>
				<Container>
					<Navbar.Brand href="#home" className="text-start">
						<img src={Logo} alt="" className="navbar-logo" style={animateHeaderHeight} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpended(v => !v)} />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
              <NavLink className="nav-link" onClick={handleClick} to="/" >HOME</NavLink>
							<NavLink className="nav-link" onClick={handleClick} to="/about">ABOUT</NavLink>
							<NavLink className="nav-link" onClick={handleClick} to="/services">SERVICES</NavLink>
							<NavLink className="nav-link" onClick={handleClick} to="/gallery">GALLERY</NavLink>
							{/* <NavLink className="nav-link" onClick={handleClick} to="/achievement">ACHIEVEMENT</NavLink> */}
              <NavLink className="nav-link" onClick={handleClick} to="/contact">CONTACT</NavLink>

							{/* <Nav.Link href="#home" to="/">
								<Link to="/">HOME</Link>
							</Nav.Link>
							<Nav.Link href="#link">
								<Link to="/about">ABOUT</Link>{" "}
							</Nav.Link>
							<Nav.Link href="#link">
								<Link to="/services">SERVICES</Link>
							</Nav.Link>
							<Nav.Link href="#link">
								<Link to="/gallery">GALLERY</Link>
							</Nav.Link>
							<Nav.Link href="#link">
								<Link to="/achievement">ACHIEVEMENT</Link>
							</Nav.Link>
							<Nav.Link href="#link">
								<Link to="/contact">CONTACT</Link>
							</Nav.Link> */}

							{/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">
            Another action
          </NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">
            Something
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">
            Separated link
          </NavDropdown.Item>
        </NavDropdown> */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
}
