import "./Footer.scss";
import Logo from "../../assets/logo.jpg";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer">
        <div className="section">
          <footer className="pt-3 mt-4">
            <div className="container">
              <div className="row">
                <div>
                  <img src={Logo} alt="" className="w-25" />
                </div>
                <div className="footer-list-item">
                  <ul>
                    <li>
                      Vip Road Virat Nagar,Alambagh 
                    </li>
                    <li> Lucknow, Uttar Pradesh, 226005</li>
                    <li><span>RESERVATIONS NUMBER</span>: +919454363536 | +918299573321 | +917459846043</li>
                    <li>Email: caterersanil604@gmail.com | piyush6306508774@gmail.com</li>
                    <li>WORKING HOURS:</li> 
                    <li>MON - SUN: 10:00 AM - 6:00 PM</li>
                  </ul>
                </div>
                <div >
                  <ul className="nav justify-content-center pb-3 mb-3">
                    <li className="nav-item">
                      <a href="#" className=" px-2 ">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className=" px-2 ">
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className=" px-2 ">
                        Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className=" px-2 ">
                        Gallery
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className=" px-2 ">
                        Contact
                      </a>
                    </li>
                  </ul>
                  <p className="text-center copy-right">
                    © Anil CATERERS 2000 - 2020. All rights reserved. Developed by -:
                    AI App Lab
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Footer;
