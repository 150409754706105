import "./About.scss";
import Owner from "../assets/owner.jpg";
import Logo from "../assets/logo.jpg";

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

function About() {
  return (
    <div className="About">
      <div className="about-wrapper d-flex">
        <div className="container d-flex justify-content-center">
          <div className="row align-content-center">
            <div className="col-lg-12">
              <div className="banner-heading">
                <div className="d-inline-block">
                  <h1>Our Story</h1>
                  <div className="heading-underline">
                    <span></span>
                    <span>
                      <img
                        src={Logo}
                        alt=""
                        className=""
                        style={{
                          marginTop: "-3rem",
                        }}
                      />
                    </span>
                    <span></span>
                  </div>
                </div>
                <h2 className="text-white">
                  The Best Caterers In Lucknow - Since 2000
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-anil-caters mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

              <div className="d-inline-block mb-3">
                <h1 className="section-heading mb-3">
                  Anil Caterers
                </h1>
                <div className="heading-underline">
                  <span></span>
                  <span>
                    <img
                      src={Logo}
                      alt=""
                      className=""
                      style={{ marginTop: "-2rem" }}
                    />
                  </span>
                  <span></span>
                </div>
              </div>

              <h1>Since 2000</h1>
              <h6 className="para mt-4">
                “Celebration is all about exceptional food and memorable experiences. The best way to choose a caterer is to rely on recommendations from those who have previously enjoyed their services. At Anil Caterers, we are a full-service catering company specializing exclusively in vegetarian dishes, offering unique presentation, outstanding taste, and innovative event styling. We employ top-tier chefs and use high-quality ingredients to deliver a gourmet food experience that delights your taste buds. Our sole aim is to make your event extraordinary. For us, the pinnacle of catering is taste, quality, and variety. We strive to create unforgettable weddings that are cherished long after the food and decor are gone. We embrace the significance of celebrations and aim to make each one remarkable."
              </h6>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6">
              <img src={Owner} alt="" className="w-100" />
            </div>
            <div className="col-lg-6">

              <p className="about-heading2">"There is no truer affection than the love for food"</p>
              <p className="para">
                We strive for nothing short of perfection, dedicating ourselves to every detail that makes your event spectacular. Our team includes top chefs, food and nutritional experts, skilled food carvers, and a stellar sales team, all working to present exquisite Indian cuisine with a blend of subtle simplicity, high-quality ingredients, and cultural heritage, enhanced with a modern touch. Rest assured, whether it's a wedding, social event, corporate gathering, or any other significant occasion, you're in the best hands. With over 28 years of culinary expertise, Anil Caterers is committed to delivering first-class gourmet food, exceptional quality, and comprehensive service.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="indoor-wrapper">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-12">

              <div className="d-inline-block mb-3">
                <h1 className="section-heading mb-3">
                  Comprehensive Venue Catering Services
                </h1>

                <div className="heading-underline">
                  <span></span>
                  <span>
                    <img
                      src={Logo}
                      alt=""
                      className=""
                      style={{ marginTop: "-2rem" }}
                    />
                  </span>
                  <span></span>
                </div>
              </div>

              <h2 className="indoor-heading-2 text-white">
                PLEASENT & RELAXING

              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <CardGroup>
          <Card>
            <Card.Img variant="top" src={Owner} className="w-100" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a wider card with supporting text below as a natural lead-in
                to additional content. This content is a little bit longer.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>

          <Card>
            <Card.Img variant="top" src={Owner} className="w-100" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a wider card with supporting text below as a natural lead-in
                to additional content. This content is a little bit longer.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src={Owner} className="w-100" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a wider card with supporting text below as a natural lead-in
                to additional content. This content is a little bit longer.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>
        </CardGroup>
      </div> */}

      <div className="staff-wrapper">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="d-inline-block mb-3">
                <h1 className="section-heading mb-3">
                  Our Most Cherished Asset


                </h1>

                <div className="heading-underline">
                  <span></span>
                  <span>
                    <img
                      src={Logo}
                      alt=""
                      className=""
                      style={{ marginTop: "-2rem" }}
                    />
                  </span>
                  <span></span>
                </div>
              </div>
              <h2 className="indoor-heading-2 text-white">
                RELIABLY COURTEOUS & PROFESSIONAL SERVICE

              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
