import "./Services.scss";
import { motion } from "framer-motion"

// import stall1 from "../assets/stall1.jpg";
import Logo from "../assets/logo.jpg";
import { MdNextPlan } from "react-icons/md";
import CateringServices1 from "../assets/catering-services1.jpg";
import CateringServices2 from "../assets/catering-serices2.jpg";
import CateringServices3 from "../assets/catering-services3.jpg";
import CateringServices4 from "../assets/catering-service4.jpg";
import CateringServices5 from "../assets/catering-services5.JPG";
import CateringServices6 from "../assets/catering-services6.jpg";
import CateringServices7 from "../assets/catering-services7.jpg";
import CateringServices8 from "../assets/catering-services8.jpg";
import CateringServices9 from "../assets/catering-services9.jpg";
import CateringServices10 from "../assets/catering-services10.jpg";
import CateringServices11 from "../assets/catering-services11.jpg";



function Services() {
  return (
    <div className="services">
      <div className="services-wrapper d-flex">
        <div className="container d-flex justify-content-center">
          <div className="row align-content-center">
            <div className="col-lg-12">
              <div className="banner-heading">
                <div className="d-inline-block">
                  <h1>Catering
                  </h1>
                  <div className="heading-underline">
                    <span></span>
                    <span>
                      <img
                        src={Logo}
                        alt=""
                        className=""
                        style={{
                          marginTop: "-3rem",
                        }}
                      />
                    </span>
                    <span></span>
                  </div>
                </div>
                <h2 className="text-white">
                  SPECIAL EVENTS
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-day mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-inline-block service-custom-heading">
                <h2 className="services-heading-1">IT'S YOUR TIME TO SHINE
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>

              <h1>MAKE IT MEMORABLE</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 d-flex justify-content-around text-center">
              <h5>Weddings</h5>
              <h5>Anniversaries</h5>
              <h5>Baby Showers</h5>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h4 className="centered">

                With a versatile and highly skilled team dedicated to innovative fine cuisine, you can rely on Anil Caterers to manage every aspect of your event with the utmost care. Whether you’re planning a small gathering or a grand affair, we offer friendly, professional service, fresh seasonal flavors, and a creative menu. Our broad range of services and flexibility—accommodating budget limits and dietary needs—have established Anil Caterers as one of the most sought-after catering services in the city of Avadh.
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="services-offer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-inline-block mb-3">
                <h1 className="our-services mb-3">
                  Our Offerings


                </h1>

                <div className="heading-underline">
                  <span></span>
                  <span>
                    <img
                      src={Logo}
                      alt=""
                      className=""
                      style={{ marginTop: "-2rem" }}
                    />
                  </span>
                  <span></span>
                </div>
              </div>

              <h1 className="our-services-2 text-white">SERVICE WE PROVIDE</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="services-stalls mt-5 mb-5">
        <div className="container">
          <div className="row align-itmes-center">
            <div className="col-lg-6 ">
              <img src={CateringServices1} alt="" className="stall-img" />
            </div>
            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">CHEF STATION SERVICE
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">

                Custom preparation and the freshest presentation make a chef station services a top choice for discerning guests. When combined with other service styles, it encourages an engaging flow of guest activity. Live cooking stations are a popular trend for private parties, weddings, and business events. A chef-attended station offers clients a flexible à la carte option in outdoor catering settings.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">

            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">MINI PLATE SAMPLERS
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                The mini plate samplers style offers a contemporary take on tray passing. Whether guests are standing or seated, they can savor the rich flavors of gourmet dishes served by our skilled chefs.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>

            <div className="col-lg-6 ">
              <img src={CateringServices2} alt="" className="stall-img" />
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">
            <div className="col-lg-6 ">
              <img src={CateringServices3} alt="" className="stall-img" />
            </div>
            <div className="col-lg-6 align-self-center text-center ">

              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">BUFFET STYLE DINNER
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">

                This is a key aspect of different buffet styles. The stationary dinner buffet, often favored for casual events, involves more setup and effort. It’s ideal for serving large groups simultaneously and is commonly used at big gatherings. Buffets are carefully organized to ensure freshness and attractive presentation, and their layout provides versatility for various types of events.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">

            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">NUTRITIOUS & DIETARY MEALS
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                Anil Caterers are now coming up with its new diet food menu where they offer. Keto diet, Low Carb diet & detox diet for those who are on diet. They are never disappointed with our catering menu. Which is the best selection of our clients who are on diet.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>

            <div className="col-lg-6 ">
              <img src={CateringServices4} alt="" className="stall-img" />
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">
            <div className="col-lg-6 ">
              <img src={CateringServices5} alt="" className="stall-img" />
            </div>
            <div className="col-lg-6 align-self-center text-center ">

              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">DINING JOURNEY
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                For a formal event, seated dinner service represents the pinnacle of hospitality. The menus are crafted with sophistication and style to make a lasting impression. The service is meticulously coordinated and exceptionally attentive. Venue and décor play crucial roles in creating the perfect seated dinner experience, and we are pleased to offer our expert recommendations.


              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">

            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">INDIAN TRADITIONAL CUISINE
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">

                A selection of pre-plated items, such as entrees, sandwich plates, and salad plates, arranged on a buffet table. These dishes may also be placed on a rolling cart or table and then brought into the event space at the scheduled time.


              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>

            <div className="col-lg-6 ">
              <img src={CateringServices6} alt="" className="stall-img" />
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">
            <div className="col-lg-6 ">
              <img src={CateringServices7} alt="" className="stall-img" />
            </div>
            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">STATIONARY PRESENTATION STATION
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                Our stationary display service highlights the food, offering guests a visually appealing and varied selection. Set up in the center of a smaller space for added impact, or integrate with other service styles for larger gatherings.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">

            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">SPECIALIZED MENU (WORLD CUISINE OPTIONS)
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                We tailor our menu to meet our clients' preferences, continually innovating and designing it each season. Our cuisine selections and recipes are creative and overseen by our Sous Chef and Executive Chefs to ensure exceptional quality and flavor.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>

            <div className="col-lg-6 ">
              <img src={CateringServices8} alt="" className="stall-img" />
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">
            <div className="col-lg-6 ">
              <img src={CateringServices9} alt="" className="stall-img" />
            </div>
            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">PRIMARILY LIVE FOOD STATIONS
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                Live food counters are the latest trend for parties. The days of cooking for guests or relying on traditional caterers for home delivery are over. Adding a live counter to a party enhances the experience, bringing a dynamic visual element that makes the event more vibrant and engaging.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">

            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">DELUXE SERVICE

                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                Deluxe service is a dining method where food is served directly at the table. This technique involves transferring food from a serving dish to the guest's plate from the left side. It is carried out by a skilled steward using service forks and spoons, ensuring a refined and attentive service experience.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>

            <div className="col-lg-6 ">
              <img src={CateringServices10} alt="" className="stall-img" />
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-itmes-center">
            <div className="col-lg-6 ">
              <img src={CateringServices11} alt="" className="stall-img" />
            </div>
            <div className="col-lg-6 align-self-center text-center ">
              <div className="d-inline-block service-custom-heading">
                <h2 className="about-heading">PLATTER SERVICE
                </h2>

                <div className="heading-underline-small">
                  <span></span>
                  <span className="circle">

                  </span>
                  <span></span>
                </div>

              </div>
              <p className="service-custom-para">
                We design and elevate tray pass service with stylish, upscale trays. This trend, which we continually refine, ensures a standout feature at every event.
              </p>

              <i className="fa-solid fa-plane-arrival"></i>
              <a href="#" className="more-details">Start Planning<MdNextPlan /> </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
