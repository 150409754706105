import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import image1 from "../assets/catering-services1.jpg";
import image2 from "../assets/catering-serices2.jpg";
import image3 from "../assets/catering-services3.jpg";
import image4 from "../assets/catering-service4.jpg";
import image5 from "../assets/catering-services5.JPG";
import image6 from "../assets/catering-services6.jpg";
import image7 from "../assets/catering-services7.jpg";
import image8 from "../assets/catering-services8.jpg";
import image9 from "../assets/catering-services9.jpg";
import image10 from "../assets/Staff.jpeg";
import image11 from "../assets/buffet.jpg";
import image12 from "../assets/foodstyling.jpg";
import image13 from "../assets/catering-services11.jpg";
import image14 from "../assets/catering-services12.jpg";
import image15 from "../assets/slider2.jpg";
import image16 from "../assets/catering-services10.jpg";

import Logo from "../assets/logo.jpg";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./GallerySection.scss";

const GallerySection = () => {
	const images = [
		{
			src: image1,
			caption: "CHEF STATION SERVICE",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>CHEF STATION SERVICE
						(Designed by - Anil Caterers)</div>
				</div>
			),
		},
		{
			src: image2,
			caption: "MINI PLATE SAMPLERS",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image3,
			caption: "BUFFET STYLE DINNER",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image4,
			caption: "NUTRITIOUS & DIETARY MEALS",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image5,
			caption: "The Dining Circle",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image6,
			caption: "DINING JOURNEY",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image7,
			caption: "INDIAN TRADITIONAL CUISINE",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image8,
			caption: "STATIONARY PRESENTATION STATION",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image9,
			caption: "SPECIALIZED MENU (WORLD CUISINE OPTIONS)",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image10,
			caption: "Savory Selections",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},

		{
			src: image11,
			caption: "PRIMARILY LIVE FOOD STATIONS",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image12,
			caption: "Delightful Dishes Catering",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image13,
			caption: "DELUXE SERVICE",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),

		},
		{
			src: image14,
			caption: "PLATTER SERVICE",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},

		{
			src: image15,
			caption: "Café Delight & Catering",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},
		{
			src: image16,
			caption: "Lavish Bites",
			customOverlay: (
				<div className="custom-overlay__caption">
					<div>Boats (Jeshu John - designerspics.com)</div>
				</div>
			),
		},

	];

	const [index, setIndex] = useState(-1);
	const [open, setOpen] = useState(false);
	const handleClick = (index, item) => {
		setIndex(index);
		setOpen(true);
	};
	return (
		<div className="GallerySection">
			<div className="row">
				<div className="col-lg-12">
					<div className="d-inline-block carousel-heading1 mt-0">
						<h1 className="our-gallery">OUR GALLERY</h1>

						<div className="heading-underline-small mb-5">
							<span></span>
							<span className="circle">

							</span>
							<span></span>
						</div>
					</div>

					{/* <Gallery
					images={images}
					// defaultContainerWidth={200}
					onClick={handleClick}
					enableImageSelection={false}
					rowHeight={400}
				/> */}
					<div className="row gallary-container">
						{images.map((image_val, image_index) => {
							return <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 py-3" key={image_index}>
								<div className="gallary-image-container" onClick={() => handleClick(image_index)} style={{ backgroundImage: `url(${image_val.src})` }}>
									<div className="overlay">{image_val.caption}</div>
								</div>
							</div>

						})}
					</div>
				</div>
				<Lightbox
					index={index}
					open={open}
					close={() => setOpen(false)}
					slides={images}
				/>
			</div>
		</div>
	);
};
export default GallerySection;
