import './App.css';
import FrontEnd from './layout/FrontEnd';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <FrontEnd/>
    </div>
  );
}

export default App;


