// import './Frontend.scss'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {FontAwesome} from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import "./FrontEnd.scss";

import Home from "../pages/Home";
import About from "../pages/About";
import Footer from "./elements/Footer";
import Services from "../pages/Services";
import Gallery from "../pages/Gallery";
import Achievement from "../pages/Achievement";
import Contact from "../pages/Contact";
import Header from "./elements/Header";

const FrontEnd = () => {

  return (
    <Router>
      <div className="frontend">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/achievement" element={<Achievement />} />
          <Route path="/contact" element={<Contact />} />

        </Routes>

        {/* Footer */}
        <Footer />

      </div>
    </Router>


  );
};

export default FrontEnd;
